import ES1 from "../files/PNP/ES/ES1.pdf";
import ES2 from "../files/PNP/ES/ES2.pdf";
import ES3 from "../files/PNP/ES/ES3.pdf";
import ES4 from "../files/PNP/ES/ES4.pdf";
import SOL1 from "../files/PNP/SOL/SOL1.pdf";

import * as utils from "../utils";

// past papers buttons
const PPfiles = utils.importAll(
  require.context("../files/PNP/PP", false, /\.(pdf)$/)
);

const PPbuttons = [];
for (let year = utils.latest_year; year > utils.earliest_year - 1; year--) {
  //name of the file
  const PPfilename = year + ".pdf";

  //if file exist, add button
  if (PPfilename in PPfiles) {
    PPbuttons.push(
      <a href={PPfiles[PPfilename]} target="blank">
        {" "}
        {year}{" "}
      </a>
    );
  }
  //if file doesn't exist, add dead button
  else {
    PPbuttons.push(<a className="invalid">{year}</a>);
  }
}

// PP solution buttons
const SOLfiles = utils.importAll(
  require.context("../files/PNP/PPSOL", false, /\.(pdf)$/)
);

const SOLbuttons = [];
for (let year = utils.latest_year; year > utils.earliest_year - 1; year--) {
  //name of the file
  const SOLfilename = "SOL" + year + ".pdf";

  //if file exist, add button
  if (SOLfilename in SOLfiles) {
    SOLbuttons.push(
      <a href={SOLfiles[SOLfilename]} target="blank">
        {" "}
        {year}{" "}
      </a>
    );
  }
  //if file doesn't exist, add dead button
  else {
    SOLbuttons.push(<a className="invalid">{year}</a>);
  }
}

export default function PNP() {
  return (
    <div>
      <h1>Particle and Nuclear Physics</h1>

      <esBlock className="esBlock">
        <div className="examples">
          <a href={ES1} target="blank">
            Example Sheet 1
          </a>
          <a href={ES2} target="blank">
            Example Sheet 2
          </a>
          <a href={ES3} target="blank">
            Example Sheet 3
          </a>
          <a href={ES4} target="blank">
            Example Sheet 4
          </a>
        </div>

        <div className="esOneAnswer">
          <a href={SOL1} target="blank">
            Solutions
          </a>
        </div>
      </esBlock>

      <h2>Past Papers/Solutions</h2>

      <ppBlock className="ppBlock">
        <div className="ppArea">{PPbuttons}</div>
        <div className="solArea">{SOLbuttons} </div>
      </ppBlock>
    </div>
  );
}
