import * as utils from "../utils";
export default function Home() {
  return (
    <div className="homeContainer">
      <div></div>
      <h1>
        Welcome to CamPhys!<img src="logo.ico" title="Jager nomnomnom"></img>
      </h1>
      <p>Your organised Part II Physics cramming resources</p>
      <p>Spend less time working and more time drinking.</p>
      <footer>
        <p>
          For any inquiries, please email <b>{utils.email}</b>
        </p>
        <p>Made by Lawrence Wu</p>
      </footer>
    </div>
  );
}
