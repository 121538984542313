import * as utils from "../utils";

export default function SCM() {
  return (
    <div className="homeContainer">
      <div></div>
      <h1>Soft Condensed Matter</h1>
      <p>
        I didn't do SCM but if you want to share any resources, feel free to
        email them to <b>{utils.email}</b>
      </p>
    </div>
  );
}
