export const latest_year = 2024;
export const earliest_year = 2008;
export const email = "camphys@protonmail.com";

export function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}
